import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Button,
  Avatar,
  CircularProgress
} from "@material-ui/core";
import { grey, pink } from "@material-ui/core/colors";
import API from "../utils/API";
import { connect } from "react-redux";
import { setToken } from "../store/actions/userActions";
import LockIcon from "@material-ui/icons/Lock";

const useStyles = makeStyles(theme => ({
  spaceBottom: {
    marginBottom: theme.spacing(3)
  },
  loginError: {
    border: "1px solid white",
    borderColor: pink[600],
    background: pink[50],
    padding: theme.spacing(2, 4),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2)
  },
  loginErrorText: {
    color: pink[600]
  },
  avatar: {
    margin: 10
  },
  avatarWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1)
  }
}));

const LoginForm = ({ onSuccess, setToken }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: ""
  });

  const handleOnChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleLogin = async e => {
    e.preventDefault();
    setHasError(false);
    setLoading(true);
    try {
      const resultData = await API.post("auth/login", values);
      const data = resultData.data ? resultData.data : null;
      // console.log(resultData, data);
      setLoading(false);
      onSuccess();
      setToken(data.access_token, data.expires_in);
    } catch (e) {
      setHasError(true);
      setLoading(false);
      console.log("FEHLER: ", e);
    }
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleLogin}>
      <div className={classes.avatarWrapper}>
        {loading && <CircularProgress className={classes.progress} />}
        {!loading && (
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
        )}
      </div>
      <Typography variant="h5" align="center" className={classes.spaceBottom}>
        Login
      </Typography>
      {hasError && (
        <div className={classes.loginError}>
          <Typography
            variant="body1"
            align="center"
            className={classes.loginErrorText}
          >
            E-Mail oder Passwort falsch
          </Typography>
        </div>
      )}
      <TextField
        autoFocus
        value={values.username}
        variant="outlined"
        onChange={handleOnChange("email")}
        label="E-Mail"
        fullWidth
        className={classes.spaceBottom}
      />
      <TextField
        value={values.password}
        variant="outlined"
        onChange={handleOnChange("password")}
        label="Passwort"
        type="password"
        fullWidth
        className={classes.spaceBottom}
      />
      <Button
        disabled={
          !Boolean(values.email) || !Boolean(values.password) || loading
        }
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
      >
        Login
      </Button>
    </form>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setToken: (token, expires_in) => dispatch(setToken(token, expires_in))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LoginForm);
