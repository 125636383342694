import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Container,
  Paper,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import API from "../utils/API";
import EditIcon from "@material-ui/icons/Edit";
import UnstyledLink from "./UnstyledLink";
import { grey } from "@material-ui/core/colors";
import Auth from "./Auth";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  rootWrapper: {
    background: grey[50],
    minHeight: "100vh"
  },
  root: {
    padding: theme.spacing(12, 4, 4, 4)
  },
  paper: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = ({ axiosConfig }) => {
  const classes = useStyles();
  const [campaigns, setCampaigns] = useState([]);
  const [addCampaignOpen, setAddCampaignOpen] = useState(false);
  const [editCampaignTitle, setEditCampaignTitle] = useState("");
  const [editCampaignId, setEditCampaignId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const resultData = await API.get("campaigns", axiosConfig);
        const data = resultData.data ? resultData.data : [];
        setCampaigns(data);
        setLoading(false);
      } catch (e) {
        setCampaigns([]);
        console.log("FEHLER: ", e);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleAddCampaign = () => {
    setAddCampaignOpen(true);
  };

  const handleEditCampaign = (event, id, title) => {
    // console.log(id);
    setAddCampaignOpen(true);
    setEditCampaignId(id);
    setEditCampaignTitle(title);
  };

  const handleAddCampaignClose = () => {
    setEditCampaignTitle("");
    setEditCampaignId(null);
    setAddCampaignOpen(false);
  };

  const handleChangeCampaignTitle = e => {
    // console.log(e.target.value);
    setEditCampaignTitle(e.target.value);
  };

  const handleSaveCampaignTitle = async () => {
    if (editCampaignTitle === "") {
      return;
    }

    if (editCampaignId) {
      try {
        const resultData = await API.patch(
          "campaigns/" + editCampaignId,
          {
            title: editCampaignTitle
          },
          axiosConfig
        );
        const data = resultData.data ? resultData.data : [];
        handleAddCampaignClose();
        setCampaigns(data);
      } catch (e) {
        setEditCampaignTitle("");
        setEditCampaignId(null);
        // setCampaigns([]);
        console.log("FEHLER: ", e);
      }
    } else {
      try {
        const resultData = await API.post(
          "campaigns",
          {
            title: editCampaignTitle
          },
          axiosConfig
        );
        const data = resultData.data ? resultData.data : [];
        handleAddCampaignClose();
        setCampaigns(data);
      } catch (e) {
        setEditCampaignTitle("");
        setEditCampaignId(null);
        // setCampaigns([]);
        console.log("FEHLER: ", e);
      }
    }
  };

  return (
    <Auth>
      <div className={classes.rootWrapper}>
        <Container maxWidth="md" className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h4" gutterBottom>
                  Kampagnen
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddCampaign}
                >
                  Kampagne erstellen
                </Button>
                <Dialog
                  open={addCampaignOpen}
                  onClose={handleAddCampaignClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Neue Kampagne
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Bitte geben Sie eine Bezeichnung für die Kampagne ein. Der
                      Titel dient nur zur internen Verwaltung.
                    </DialogContentText>
                    <TextField
                      value={editCampaignTitle}
                      onChange={handleChangeCampaignTitle}
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Titel"
                      type="title"
                      variant="outlined"
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleAddCampaignClose} color="primary">
                      Abbrechen
                    </Button>
                    <Button onClick={handleSaveCampaignTitle} color="primary">
                      Speichern
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>

            {campaigns.length === 0 && !loading && (
              <Typography variant="body2">Keine Kampagnen vorhanden</Typography>
            )}
            {loading && (
              <Typography variant="body2">Daten werden geladen...</Typography>
            )}
            <List>
              {campaigns.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    component={UnstyledLink}
                    to={"/kampagne/" + item.id}
                  >
                    <ListItemText primary={item.title} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="bearbeiten"
                        onClick={event => {
                          handleEditCampaign(event, item.id, item.title);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Container>
      </div>
    </Auth>
  );
};

const mapStateToProps = state => {
  return {
    axiosConfig: state.user.axiosConfig
  };
};

export default connect(mapStateToProps)(Dashboard);
