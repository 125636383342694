import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import MailIcon from "@material-ui/icons/Mail";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from "@material-ui/core";
import UnstyledLink from "./UnstyledLink";
import API from "../utils/API";
import { connect } from "react-redux";
import { removeToken } from "../store/actions/userActions";
import User from "./User";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    marginRight: theme.spacing(4),
    flexGrow: 1,
    display: "flex",
    alignItems: "center"
  },
  titleIcon: {
    marginRight: theme.spacing(2)
  },
  spaceBottom: {
    marginBottom: theme.spacing(2)
  },
  spaceLeft: {
    marginLeft: theme.spacing(2)
  }
}));

const fields = [
  "mail_driver",
  "mail_host",
  "mail_port",
  "mail_username",
  "mail_password",
  "mail_encryption",
  "mail_from_address",
  "mail_from_name"
];

const NavBar = ({ token, removeToken, axiosConfig }) => {
  const classes = useStyles();
  const [openSettings, setOpenSettings] = useState(false);
  const [settings, setSettings] = useState(null);
  const [previousSettings, setPreviousSettings] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.get("settings", axiosConfig);
        const data = resultData.data ? resultData.data : [];
        setSettings(data);
        setPreviousSettings(data);
      } catch (e) {
        setSettings(null);
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  if (!token) {
    return null;
  }

  const handleLogout = async () => {
    try {
      const resultData = await API.post("auth/logout", null, axiosConfig);
      const data = resultData.data ? resultData.data : null;
      // console.log(resultData, data);
    } catch (e) {
      console.log("FEHLER: ", e);
    }
    removeToken();
  };

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleSettingsClose = () => {
    setSettings(previousSettings);
    setOpenSettings(false);
  };

  const handleOnChange = name => event => {
    setSettings({ ...settings, [name]: event.target.value });
  };

  const handleSaveSettings = async () => {
    try {
      const resultData = await API.put("settings", settings, axiosConfig);
      const data = resultData.data ? resultData.data : [];
      setSettings(data);
      setPreviousSettings(data);
      setOpenSettings(false);
    } catch (e) {
      setSettings(previousSettings);
      setOpenSettings(false);
      console.log("FEHLER: ", e);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar>
            <Typography
              variant="h6"
              className={classes.title}
              component={UnstyledLink}
              to="/"
            >
              <MailIcon className={classes.titleIcon} />
              Mailing-App
            </Typography>
            <Button
              onClick={handleOpenSettings}
              color="secondary"
              variant="contained"
              className={classes.spaceLeft}
            >
              Mail-Server
            </Button>
            <Button
              onClick={handleLogout}
              variant="contained"
              color="primary"
              className={classes.spaceLeft}
            >
              Logout
            </Button>
            <User />
          </Toolbar>
        </Container>
      </AppBar>
      <Dialog
        open={openSettings}
        onClose={handleSettingsClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Mail-Server Einstellungen
        </DialogTitle>
        <DialogContent>
          {fields.map((field, index) => (
            <TextField
              key={index}
              value={settings ? (settings[field] ? settings[field] : "") : ""}
              variant="outlined"
              onChange={handleOnChange(field)}
              label={field}
              fullWidth
              className={classes.spaceBottom}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSettingsClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleSaveSettings} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.user.token,
    axiosConfig: state.user.axiosConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeToken: () => dispatch(removeToken())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar);
