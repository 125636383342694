const initState = {
  token: null,
  tokenExpiresAt: null,
  axiosConfig: {}
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.token,
        tokenExpiresAt: action.tokenExpiresAt,
        axiosConfig: {
          headers: { Authorization: "bearer " + action.token }
        }
      };
    case "REMOVE_TOKEN":
      return {
        ...state,
        token: null,
        tokenExpiresAt: null,
        axiosConfig: {}
      };
    default:
      return state;
  }
};

export default userReducer;
