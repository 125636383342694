import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import NavBar from "./components/NavBar";
import FileUpload from "./components/FileUpload";
import Campaign from "./components/Campaign";
import Login from "./components/Login";
import { connect } from "react-redux";
import AuthWatcher from "./components/AuthWatcher";

const App = ({ token }) => {
  return (
    <BrowserRouter>
      <AuthWatcher>
        {Boolean(token) && <NavBar />}
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/login" component={Login} />
          <Route path="/upload/:campaignId" component={FileUpload} />
          <Route path="/kampagne/:campaignId" component={Campaign} />
        </Switch>
      </AuthWatcher>
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

export default connect(mapStateToProps)(App);
