import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { removeToken } from "../store/actions/userActions";
import LoginForm from "./LoginForm";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(1, 5, 7, 5)
  }
}));

const AuthWatcher = props => {
  const { tokenExpiresAt } = props;
  const classes = useStyles();
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const path = window.location.pathname;

      if (path !== "/login") {
        if (!tokenExpiresAt) {
          setShowLogin(true);
        } else {
          const date = new Date();
          const diff = Math.floor((tokenExpiresAt - date.getTime()) / 1000);

          // 10 Minuten vor Ablauf Login anzeigen
          if (diff < 10 * 60) {
            setShowLogin(true);
          }
        }
      }
    }, 20000);
    return () => clearInterval(interval);
  }, [tokenExpiresAt]);

  const handleOnSuccess = () => {
    setShowLogin(false);
  };

  return (
    <>
      {props.children}
      {showLogin && (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={showLogin}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className={classes.content}>
            <LoginForm onSuccess={handleOnSuccess} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    tokenExpiresAt: state.user.tokenExpiresAt,
    token: state.user.token,
    axiosConfig: state.user.axiosConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeToken: () => dispatch(removeToken())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthWatcher);
