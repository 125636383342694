import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Container
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import API from "../utils/API";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(4)
  },
  spaceBottom: {
    marginBottom: theme.spacing(2)
  }
}));

const CampaignFileName = ({
  campaignId,
  fileName,
  onCompleted,
  axiosConfig
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(fileName ? fileName : "");

  const handleOnChange = e => {
    if (e.target.value === "") {
      setValue("");
    }
    const letterNumber = /^[0-9a-zA-Z[\]-_.]+$/;
    if (e.target.value.match(letterNumber)) {
      setValue(e.target.value);
    }
  };

  const handleSave = async () => {
    try {
      await API.patch(
        "campaigns/" + campaignId,
        {
          file_name_template: value
        },
        axiosConfig
      );
      onCompleted();
    } catch (e) {
      console.log("FEHLER: ", e);
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Paper className={classes.paper}>
          <Typography variant="body1" className={classes.spaceBottom}>
            Bitte geben Sie das Muster des Dateinamens ein. Z.B.:
            <br />
            <strong>Einladung-Eurecon_[id].pdf</strong>
          </Typography>
          <Typography variant="body2" className={classes.spaceBottom}>
            Achten Sie auf Klein- und Großschreibung (auch bei der Dateiendung).
            <br />
            Der einzige erlaubte Platzhalter ist <strong>[id]</strong>
            <br />
            Erlaubte Sonderzeichen sind - und _ sowie ein . vor der Dateiendung.
            <br />
            Andere Sonderzeichen sowie Leerzeichen und Umlaute sind nicht
            erlaubt!
          </Typography>
          <TextField
            value={value}
            variant="outlined"
            onChange={handleOnChange}
            label="Aufbau Dateiname"
            fullWidth
            className={classes.spaceBottom}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSave}
            className={classes.spaceBottom}
          >
            speichern
          </Button>
        </Paper>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    axiosConfig: state.user.axiosConfig
  };
};

export default connect(mapStateToProps)(CampaignFileName);
