import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const Auth = props => {
  const { token } = props;

  if (!token) {
    return <Redirect to="/login" />;
  }

  return <>{props.children}</>;
};

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

export default connect(mapStateToProps)(Auth);
