import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { grey, amber } from "@material-ui/core/colors";
import { Typography, Container, Divider } from "@material-ui/core";
import UploadPreviewTable from "./UploadPreviewTable";
import csv from "csv";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  area: {
    padding: theme.spacing(4),
    background: grey[100],
    border: "6px dashed white",
    borderColor: grey[300],
    height: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(4, 0)
  },
  dropText: {
    fontSize: 20,
    color: grey[900]
  },
  dragText: {
    fontSize: 20,
    color: grey[500]
  },
  warning: {
    background: amber[100],
    padding: theme.spacing(2, 4),
    margin: theme.spacing(4, 0)
  }
}));

const FileUpload = ({ campaignId, onCompleted }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [removedRows, setRemovedRows] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, { delimiter: ";", bom: true }, (err, data) => {
        console.log(err, data);
        if (err) {
          console.log("FEHLER");
          return;
        }
        if (data) {
          const json = data.map(item => {
            const jsonItem = {
              id: item[0].trim(),
              title: item[1].trim(),
              gender: item[2].trim(),
              first_name: item[3].trim(),
              last_name: item[4].trim(),
              email: item[5].trim(),
              template_identifier: item[6].trim(),
              campaign_id: campaignId
            };
            return jsonItem;
          });
          // console.log("JSON", json);
          const usedJson = json.filter(j => j.email.indexOf("@") !== -1);
          const removedJson = json.filter(j => j.email.indexOf("@") === -1);
          setRows(usedJson);
          setRemovedRows(removedJson);
        }
      });
    };

    reader.readAsText(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleOnCompleted = () => {
    setRows([]);
    setRemovedRows([]);
    onCompleted();
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <div className={classes.warning}>
        <Typography variant="body1">
          <strong>Achtung! </strong>Bereits importierte Adressen in dieser
          Kampagne werden <strong>gelöscht</strong>.
        </Typography>
      </div>
      <div {...getRootProps()} className={classes.area}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="h3" className={classes.dropText}>
            loslassen ...
          </Typography>
        ) : (
          <Typography variant="h3" className={classes.dragText}>
            CSV-Datei hierhinziehen oder klicken
          </Typography>
        )}
      </div>

      {removedRows.length > 0 && (
        <Typography variant="h6" gutterBottom>
          {removedRows.length} Zeile(n) entfernt (ggf. handelt es sich um die
          Kopfzeile):
        </Typography>
      )}
      {removedRows.map((rem, i) => (
        <div key={i}>
          <Typography variant="body2" gutterBottom>
            {rem.id}; {rem.title}; {rem.gender}; {rem.first_name};{" "}
            {rem.last_name}; {rem.template_identifier};{" "}
          </Typography>
          <Divider />
        </div>
      ))}

      {rows.length > 0 && (
        <UploadPreviewTable rows={rows} onCompleted={handleOnCompleted} />
      )}
    </Container>
  );
};

export default FileUpload;
