import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Tabs,
  Tab,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import API from "../utils/API";
import MailingStatusTable from "./MailingStatusTable";
import FileUpload from "./FileUpload";
import PdfUpload from "./PdfUpload";
import { grey } from "@material-ui/core/colors";
import CampaignFileName from "./CampaignFileName";
import CampaignMailTemplateList from "./CampaignMailTemplateList";
import Auth from "./Auth";
import { connect } from "react-redux";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import UnstyledLink from "./UnstyledLink";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(12, 6, 6, 6),
    background: grey[50],
    minHeight: "100vh"
  },
  paper: {
    padding: theme.spacing(4)
  },
  actionBtn: {
    marginLeft: theme.spacing(2)
  },
  tabbar: {
    marginTop: theme.spacing(3)
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center"
  },
  titleIcon: {
    marginRight: theme.spacing(2)
  }
}));

const Campaign = ({ match, axiosConfig }) => {
  const classes = useStyles();
  const [campaign, setCampaign] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const campaignId = match.params.campaignId;

  async function fetchData() {
    try {
      const resultData = await API.get("campaigns/" + campaignId, axiosConfig);
      const data = resultData.data ? resultData.data : null;
      setCampaign(data && data.campaign ? data.campaign : null);
      setAddresses(data && data.addresses ? data.addresses : null);
    } catch (e) {
      setCampaign(null);
      console.log("FEHLER: ", e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleBackToFirstTab = () => {
    fetchData();
    setTabValue(0);
  };

  const handleChangeTab = (e, newValue) => {
    setTabValue(newValue);
  };

  if (!campaign) {
    return <Auth />;
  }

  return (
    <Auth>
      <Container maxWidth="xl" className={classes.root}>
        <div className={classes.titleWrapper}>
          <UnstyledLink to="/">
            <IconButton className={classes.titleIcon}>
              <KeyboardBackspaceIcon fontSize="large" />
            </IconButton>
          </UnstyledLink>
          <Typography variant="h3">{campaign.title}</Typography>
        </div>
        <Paper className={classes.tabbar}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Übersicht" />
            <Tab label="Adressen importieren" />
            <Tab label="PDF-Dateien" />
            <Tab label="Dateiname" />
            <Tab label="Anschreiben" />
          </Tabs>
        </Paper>

        {tabValue === 0 && addresses && addresses.length > 0 && (
          <MailingStatusTable
            rows={addresses}
            campaignId={campaignId}
            onCompleted={fetchData}
            baseUrl={"https://eurecon-api.wamedia.io/pdf/" + campaignId + "/"}
          />
        )}
        {tabValue === 1 && (
          <FileUpload
            campaignId={campaignId}
            onCompleted={handleBackToFirstTab}
          />
        )}
        {tabValue === 2 && (
          <PdfUpload campaignId={campaignId} onCompleted={fetchData} />
        )}
        {tabValue === 3 && (
          <CampaignFileName
            campaignId={campaignId}
            fileName={campaign.file_name_template}
            onCompleted={fetchData}
          />
        )}
        {tabValue === 4 && (
          <CampaignMailTemplateList
            campaignId={campaignId}
            onCompleted={handleBackToFirstTab}
          />
        )}
      </Container>
    </Auth>
  );
};

const mapStateToProps = state => {
  return {
    axiosConfig: state.user.axiosConfig
  };
};

export default connect(mapStateToProps)(Campaign);
