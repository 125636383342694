import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Container,
  Paper,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { grey } from "@material-ui/core/colors";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(4)
  },
  fileLink: {
    textDecoration: "none",
    color: grey[900]
  }
}));

const PdfList = ({ files, handleDelete, baseUrl, token }) => {
  const classes = useStyles();
  const [deleteFileName, setDeleteFileName] = useState(null);

  const handleOpenDelete = (event, item) => {
    setDeleteFileName(item);
  };

  const handleCloseDelete = () => {
    setDeleteFileName(null);
  };

  const handleConfirmedDelete = (event, item) => {
    handleDelete(event, item);
    handleCloseDelete();
  };

  return (
    <>
      <Container maxWidth="md" className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4" gutterBottom>
                Dateien
              </Typography>
            </Grid>
            <Grid item xs={6} align="right"></Grid>
          </Grid>

          <List>
            {files.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={
                      <a
                        className={classes.fileLink}
                        href={baseUrl + item + "?token=" + token}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {item}
                      </a>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="löschen"
                      onClick={event => {
                        handleOpenDelete(event, item);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Container>
      <Dialog
        open={Boolean(deleteFileName)}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Datei wirklich löschen?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchten Sie die Datei {deleteFileName} wirklich löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Abbrechen
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={event => {
              handleConfirmedDelete(event, deleteFileName);
            }}
            autoFocus
          >
            Ja, löschen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    token: state.user.token,
    axiosConfig: state.user.axiosConfig
  };
};

export default connect(mapStateToProps)(PdfList);
