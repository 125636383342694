import React, { useState, useEffect } from "react";
import API from "../utils/API";
import { connect } from "react-redux";
import {
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Button,
  Typography,
  DialogTitle
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  btn: {
    color: "white",
    marginLeft: theme.spacing(4),
    opacity: 0.8
  },
  spaceBottom: {
    marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(3, 4, 4, 4)
  }
}));

const User = ({ axiosConfig }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: ""
  });
  const [credentials, setCredentials] = useState({
    currentPassword: "",
    password: "",
    repeatPassword: ""
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.post("auth/me", null, axiosConfig);
        const data = resultData.data ? resultData.data : [];
        setUserData(data);
        console.log(data);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  const handleSave = async () => {
    // console.log("Speichern");
    setLoading(true);
    try {
      const resultData = await API.put(
        "auth/me",
        {
          password: credentials.password,
          currentPassword: credentials.currentPassword
        },
        axiosConfig
      );
      const data = resultData.data ? resultData.data : [];
      setUserData(data);
      console.log(data);
    } catch (e) {
      console.log("FEHLER: ", e);
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = name => event => {
    setCredentials({ ...credentials, [name]: event.target.value });
  };

  return (
    <>
      <IconButton onClick={handleOpen} className={classes.btn}>
        <AccountCircleIcon />
      </IconButton>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Passwort ändern</DialogTitle>
        <DialogContent className={classes.content}>
          <Typography variant="body1" className={classes.spaceBottom}>
            {userData.email}
          </Typography>
          <TextField
            value={credentials.currentPassword}
            variant="outlined"
            onChange={handleOnChange("currentPassword")}
            label="Altes Passwort"
            type="password"
            fullWidth
            className={classes.spaceBottom}
          />
          <TextField
            value={credentials.password}
            variant="outlined"
            onChange={handleOnChange("password")}
            label="Neues Passwort"
            type="password"
            fullWidth
            className={classes.spaceBottom}
          />
          <TextField
            value={credentials.repeatPassword}
            variant="outlined"
            onChange={handleOnChange("repeatPassword")}
            label="Neues Passwort wiederholen"
            type="password"
            fullWidth
            className={classes.spaceBottom}
          />
          <Button
            disabled={
              credentials.password !== credentials.repeatPassword || loading
            }
            onClick={handleSave}
            variant="contained"
            color="primary"
            fullWidth
          >
            Passwort ändern
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = state => {
  return {
    token: state.user.token,
    axiosConfig: state.user.axiosConfig
  };
};

export default connect(mapStateToProps)(User);
