import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { grey, amber } from "@material-ui/core/colors";
import { Typography, Container, CircularProgress } from "@material-ui/core";
import API from "../utils/API";
import PdfList from "./PdfList";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  area: {
    padding: theme.spacing(4),
    background: grey[100],
    border: "6px dashed white",
    borderColor: grey[300],
    height: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(4, 0)
  },
  dropText: {
    fontSize: 20,
    color: grey[900]
  },
  dragText: {
    fontSize: 20,
    color: grey[500]
  },
  warning: {
    background: amber[100],
    padding: theme.spacing(2, 4),
    margin: theme.spacing(4, 0)
  },
  progress: {
    margin: theme.spacing(10)
  }
}));

const PdfUpload = ({ campaignId, axiosConfig, token, onCompleted }) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [storedFiles, setStoredFiles] = useState([]);
  const [waiting, setWaiting] = useState(false);

  async function fetchData() {
    try {
      const resultData = await API.get("pdf/" + campaignId, axiosConfig);
      const data = resultData.data ? resultData.data : [];
      console.log(data);
      setStoredFiles(data ? data : []);
    } catch (e) {
      console.log("FEHLER: ", e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const sendRequest = file => {
    console.log("send");
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      /*
      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
         const copy = { ...this.state.uploadProgress };
         copy[file.name] = {
          state: "pending",
          percentage: (event.loaded / event.total) * 100
         };
         this.setState({ uploadProgress: copy });
        }
       });
        
       */

      req.upload.addEventListener("load", event => {
        /*
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        */
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        /*
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        */
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("pdf", file, file.name);

      req.open("POST", "https://eurecon-api.wamedia.io/pdf/" + campaignId);
      req.setRequestHeader("Authorization", "Bearer " + token);
      req.send(formData);
    });
  };

  const uploadFiles = async () => {
    setWaiting(true);
    const promises = [];
    files.forEach(file => {
      console.log("File");
      promises.push(sendRequest(file));
    });
    console.log("weiter", promises);
    Promise.all(promises).then(
      values => {
        console.log("Fertig");
        setFiles([]);
        setWaiting(false);
        fetchData();
        onCompleted();
      },
      reason => {
        console.log(reason);
      }
    );

    /*
    try {
      await Promise.all(promises);
      console.log("Fertig");
      setFiles([]);
      fetchData();
    } catch (e) {
      console.log("Fertig2");
      console.log(e);
      // Not Production ready! Do some error handling here instead...
    }
    */
  };

  useEffect(() => {
    if (files.length) {
      uploadFiles();
    }
  }, [files]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDelete = async (event, item) => {
    try {
      const resultData = await API.delete(
        "pdf/" + campaignId + "/" + item,
        axiosConfig
      );
      const data = resultData.data ? resultData.data : [];
      console.log(resultData);
      fetchData();
      onCompleted();
    } catch (e) {
      console.log("FEHLER: ", e);
    }
  };

  const viewUpload = (
    <>
      <Container maxWidth="xl" className={classes.root}>
        <div className={classes.warning}>
          <Typography variant="body1">
            <strong>Achtung! </strong>Vorhandene Dateien mit gleichem Namen
            werden <strong>überschrieben</strong>.
          </Typography>
        </div>
        <div {...getRootProps()} className={classes.area}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant="h3" className={classes.dropText}>
              loslassen ...
            </Typography>
          ) : (
            <Typography variant="h3" className={classes.dragText}>
              PDF-Datei(en) hierhinziehen oder klicken
            </Typography>
          )}
        </div>
      </Container>
      <PdfList
        files={storedFiles}
        handleDelete={handleDelete}
        baseUrl={"https://eurecon-api.wamedia.io/pdf/" + campaignId + "/"}
      />
    </>
  );

  const viewWaiting = (
    <>
      <CircularProgress className={classes.progress} />
    </>
  );

  return <>{waiting ? viewWaiting : viewUpload}</>;
};

const mapStateToProps = state => {
  return {
    token: state.user.token,
    axiosConfig: state.user.axiosConfig
  };
};

export default connect(mapStateToProps)(PdfUpload);
