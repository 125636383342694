import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from "@material-ui/icons/Check";
import SendIcon from "@material-ui/icons/Send";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Divider
} from "@material-ui/core";
import API from "../utils/API";
import { green, pink, grey } from "@material-ui/core/colors";
import Moment from "react-moment";
import { connect } from "react-redux";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "internal_id",
    numeric: true,
    disablePadding: false,
    label: "Id"
  },
  { id: "title", numeric: false, disablePadding: false, label: "Titel" },
  { id: "gender", numeric: false, disablePadding: false, label: "Gender" },
  { id: "first_name", numeric: false, disablePadding: false, label: "Vorname" },
  { id: "last_name", numeric: false, disablePadding: false, label: "Nachname" },
  { id: "email", numeric: false, disablePadding: false, label: "E-Mail" },
  {
    id: "template_identifier",
    numeric: false,
    disablePadding: false,
    label: "Template-Identifier"
  },
  { id: "file", numeric: false, disablePadding: false, label: "Datei" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? "left" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
              {orderBy === row.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="none">Gesendet</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  },
  btnIcon: {
    marginRight: theme.spacing(2)
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    numTotal,
    selected,
    campaignId,
    onCompleted,
    axiosConfig
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [completed, setCompleted] = useState(0);
  const [errors, setErrors] = useState([]);

  async function handleSend(event) {
    setErrors([]);
    setCompleted(0);
    setDialogOpen(true);
    const mailErrors = [];
    for (let index = 0; index < selected.length; index++) {
      try {
        const result = await API.post(
          "mail/" + campaignId,
          {
            entries: [selected[index]]
          },
          axiosConfig
        );
        // ("RESULT", result);
        if (result.data[0] && result.data[0].result.status === "error") {
          mailErrors.push(result.data[0]);
          setErrors(mailErrors);
        }
        // console.log(result);
      } catch (e) {
        mailErrors.push({
          address: { email: "" },
          result: { message: e.message }
        });
        setErrors(mailErrors);
        console.log("FEHLER: ", e);
      }
      setCompleted(((index + 1) / selected.length) * 100);
    }

    onCompleted();
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} von {numTotal} ausgewählt
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              Adressen ({numTotal})
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 && (
            <Button
              aria-label="senden"
              variant="contained"
              color="primary"
              onClick={handleSend}
            >
              <SendIcon className={classes.btnIcon} /> senden
            </Button>
          )}
        </div>
      </Toolbar>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {completed < 100 ? "E-Mails werden gesendet..." : "Fertig!"}
        </DialogTitle>
        <LinearProgress variant="determinate" value={completed} />
        <DialogContent>
          <Typography variant="h4" component="div" align="center">
            {completed} %
          </Typography>
          {completed === 100 && errors.length === 0 && (
            <Typography variant="body1" component="div" align="center">
              Keine Fehler
            </Typography>
          )}
          {completed === 100 && errors.length > 0 && (
            <Typography variant="body1" component="div" align="left">
              {errors.length} Fehler:
            </Typography>
          )}
          {errors.map((e, i) => (
            <div key={i}>
              <Typography variant="body2" component="div">
                {e.address.email} <strong>{e.result.message}</strong>
              </Typography>
              <Divider />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="primary"
            disabled={completed < 100}
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  numTotal: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  success: {
    color: green[500],
    marginRight: theme.spacing(1)
  },
  error: {
    color: pink[500],
    marginRight: theme.spacing(1)
  },
  role: {
    color: grey[600]
  },
  status: {
    display: "flex",
    alignItems: "center"
  },
  tooltipContent: {
    display: "flex"
  },
  fileLink: {
    textDecoration: "none",
    color: grey[900]
  }
}));

const MailingStatusTable = ({
  rows,
  campaignId,
  onCompleted,
  baseUrl,
  axiosConfig,
  token
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.filter(r => r.email).map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleSendingCompleted() {
    onCompleted();
    setSelected([]);
  }

  function handleClick(event, id, email) {
    if (!email) {
      return;
    }

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          numTotal={rows.length}
          selected={selected}
          campaignId={campaignId}
          onCompleted={handleSendingCompleted}
          axiosConfig={axiosConfig}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.id, row.email)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={!row.email}
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.internal_id}
                      </TableCell>
                      <TableCell align="left">{row.title}</TableCell>
                      <TableCell align="left">{row.gender}</TableCell>
                      <TableCell align="left">{row.first_name}</TableCell>
                      <TableCell align="left">{row.last_name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        {row.template_identifier}
                      </TableCell>
                      <TableCell align="left">
                        {row.file && (
                          <a
                            className={classes.fileLink}
                            href={baseUrl + row.file + "?token=" + token}
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            {row.file}
                          </a>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.sent_mails.map((sMail, sIndex) => (
                          <Tooltip
                            key={sIndex}
                            placement="left"
                            title={
                              <>
                                <Typography variant="body2">
                                  {"von " + sMail.sender} <br />
                                  {" an " + sMail.recipient}
                                </Typography>
                                {Boolean(sMail.message) && (
                                  <Typography variant="body2">
                                    {sMail.message}
                                  </Typography>
                                )}
                              </>
                            }
                          >
                            <Typography
                              variant="body2"
                              component="div"
                              className={classes.status}
                            >
                              {sMail.result === "success" ? (
                                <CheckIcon className={classes.success} />
                              ) : (
                                <ErrorOutlineIcon className={classes.error} />
                              )}
                              <Moment format="DD.MM.YYYY HH:mm">
                                {sMail.created_at}
                              </Moment>
                            </Typography>
                          </Tooltip>
                        ))}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.user.token,
    axiosConfig: state.user.axiosConfig
  };
};

export default connect(mapStateToProps)(MailingStatusTable);
