import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Container,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  ListItemText,
  ListItem,
  List,
  ListItemSecondaryAction,
  IconButton,
  DialogActions,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import API from "../utils/API";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(4)
  },
  spaceBottom: {
    marginBottom: theme.spacing(2)
  },
  spaceBottom2: {
    marginBottom: theme.spacing(4)
  }
}));

const defaultEditTemplate = {
  email_subject: "",
  email_text: "",
  is_default: false,
  identifier: ""
};

const CampaignMailTemplateList = ({ campaignId, onCompleted, axiosConfig }) => {
  const classes = useStyles();
  const [templates, setTemplates] = useState([]);
  const [addTemplateOpen, setAddTemplateOpen] = useState(false);
  const [editTemplate, setEditTemplate] = useState(defaultEditTemplate);
  const [editTemplateId, setEditTemplateId] = useState(null);
  const [deleteTemplate, setDeleteTemplate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const resultData = await API.get(
          "campaigns/" + campaignId + "/templates",
          axiosConfig
        );
        const data = resultData.data ? resultData.data : [];
        setTemplates(data);
        setLoading(false);
      } catch (e) {
        setTemplates([]);
        console.log("FEHLER: ", e);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleAddTemplate = () => {
    setAddTemplateOpen(true);
  };

  const handleEditTemplate = (event, item) => {
    // console.log(item.id);
    setAddTemplateOpen(true);
    setEditTemplateId(item.id);
    setEditTemplate(item);
  };

  const handleAddTemplateClose = () => {
    setEditTemplate(defaultEditTemplate);
    setEditTemplateId(null);
    setAddTemplateOpen(false);
  };

  const handleSaveTemplate = async () => {
    if (!editTemplate) {
      return;
    }

    if (editTemplateId) {
      try {
        const resultData = await API.put(
          "campaigns/" + campaignId + "/templates/" + editTemplateId,
          editTemplate,
          axiosConfig
        );
        const data = resultData.data ? resultData.data : [];
        handleAddTemplateClose();
        setTemplates(data);
      } catch (e) {
        setEditTemplate(defaultEditTemplate);
        setEditTemplateId(null);
        console.log("FEHLER: ", e);
      }
    } else {
      try {
        const resultData = await API.post(
          "campaigns/" + campaignId + "/templates",
          editTemplate,
          axiosConfig
        );
        const data = resultData.data ? resultData.data : [];
        handleAddTemplateClose();
        setTemplates(data);
      } catch (e) {
        setEditTemplate(defaultEditTemplate);
        setEditTemplateId(null);
        // setCampaigns([]);
        console.log("FEHLER: ", e);
      }
    }
  };

  const handleOnChange = name => event => {
    setEditTemplate({ ...editTemplate, [name]: event.target.value });
  };

  const handleOnChangeCheckbox = name => event => {
    const val = event.target.checked;
    if (val === true) {
      setEditTemplate({ ...editTemplate, [name]: val, identifier: "" });
    } else {
      setEditTemplate({ ...editTemplate, [name]: val });
    }
  };

  const handleOpenDelete = (event, item) => {
    setDeleteTemplate(item);
  };

  const handleCloseDelete = () => {
    setDeleteTemplate(null);
  };

  const handleConfirmedDelete = async (event, item) => {
    try {
      const resultData = await API.delete(
        "campaigns/" + campaignId + "/templates/" + item.id,
        axiosConfig
      );
      const data = resultData.data ? resultData.data : [];
      handleCloseDelete();
      setTemplates(data);
    } catch (e) {
      handleCloseDelete();
      console.log("FEHLER: ", e);
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4" gutterBottom>
                E-Mail Templates
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddTemplate}
              >
                Template erstellen
              </Button>
              <Dialog
                open={addTemplateOpen}
                onClose={handleAddTemplateClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Neues Template</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Bitte tragen Sie die Daten für das neue Template ein
                  </DialogContentText>
                  <FormControlLabel
                    className={classes.spaceBottom2}
                    control={
                      <Checkbox
                        checked={editTemplate ? editTemplate.is_default : false}
                        onChange={handleOnChangeCheckbox("is_default")}
                        value="is_default"
                        color="primary"
                      />
                    }
                    label="Standard-Template für diese Kampagne"
                  />
                  {editTemplate && !editTemplate.is_default && (
                    <TextField
                      value={editTemplate ? editTemplate.identifier : ""}
                      variant="outlined"
                      onChange={handleOnChange("identifier")}
                      label="Identifier"
                      helperText="Das Template wird nur für Adressen verwendet, die mit diesem Identifier gekennzeichnet sind. Bitte auf Klein- und Großschreibung achten!"
                      fullWidth
                      className={classes.spaceBottom2}
                    />
                  )}
                  <TextField
                    value={editTemplate ? editTemplate.email_subject : ""}
                    variant="outlined"
                    onChange={handleOnChange("email_subject")}
                    label="Betreffzeile"
                    fullWidth
                    className={classes.spaceBottom}
                  />
                  <TextField
                    multiline
                    rows={16}
                    value={editTemplate ? editTemplate.email_text : ""}
                    variant="outlined"
                    onChange={handleOnChange("email_text")}
                    label="Anschreiben"
                    fullWidth
                    className={classes.spaceBottom}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAddTemplateClose} color="primary">
                    Abbrechen
                  </Button>
                  <Button
                    onClick={handleSaveTemplate}
                    color="primary"
                    disabled={
                      !editTemplate ||
                      (!editTemplate.is_default && !editTemplate.identifier)
                    }
                  >
                    Speichern
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>

          {templates.length === 0 && !loading && (
            <Typography variant="body2">Keine Templates vorhanden</Typography>
          )}
          {loading && (
            <Typography variant="body2">Daten werden geladen...</Typography>
          )}
          <List>
            {templates.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={item.email_subject}
                    secondary={
                      item.is_default
                        ? "Standard-Template"
                        : item.identifier
                        ? item.identifier
                        : null
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="bearbeiten"
                      onClick={event => {
                        handleEditTemplate(event, item);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="löschen"
                      onClick={event => {
                        handleOpenDelete(event, item);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Container>
      <Dialog
        open={Boolean(deleteTemplate)}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Template wirklich löschen?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchten Sie die Datei{" "}
            {deleteTemplate ? deleteTemplate.email_subject : ""} wirklich
            löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Abbrechen
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={event => {
              handleConfirmedDelete(event, deleteTemplate);
            }}
            autoFocus
          >
            Ja, löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    axiosConfig: state.user.axiosConfig
  };
};

export default connect(mapStateToProps)(CampaignMailTemplateList);
