import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Paper,
  TextField,
  Button,
  Avatar,
  CircularProgress
} from "@material-ui/core";
import { grey, pink } from "@material-ui/core/colors";
import API from "../utils/API";
import { connect } from "react-redux";
import { setToken } from "../store/actions/userActions";
import MailIcon from "@material-ui/icons/Mail";
import LockIcon from "@material-ui/icons/Lock";
import LoginForm from "./LoginForm";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    minHeight: "100vh",
    overflow: "hidden"
  },
  spaceBottom: {
    marginBottom: theme.spacing(3)
  },
  keyvisual: {
    left: "-9vw",
    color: "white",
    width: "50vw",
    bottom: "-22vh",
    height: "auto",
    opacity: 0.1,
    position: "fixed",
    transform: "rotate(-20deg)"
  },
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(4)
  }
}));

const Login = ({ setToken, token }) => {
  const classes = useStyles();

  const handleOnSuccess = () => {
    console.log("success");
  };

  if (token) {
    return <Redirect push to="/" />;
  }

  return (
    <div className={classes.root}>
      <MailIcon className={classes.keyvisual} />
      <Container maxWidth="sm">
        <Paper className={classes.paper}>
          <LoginForm onSuccess={handleOnSuccess} />
        </Paper>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToken: (token, expires_in) => dispatch(setToken(token, expires_in))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
